import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { NavLink } from 'react-router-dom'
import '../styles/Navbar.css'

import Grupos from './Grupos'

const config = require('../../config'); // url de la API

class NavbarLMB extends React.Component {

    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        }
    }

    componentDidMount() {


        this.fetchData();
        this.intervalId = setInterval(this.fetchData, 500000);

    }


    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }


    fetchData = async() => {
        this.setState({ loading: true, error: null })

        try {

            fetch(`${config.api.url}/grupos/publicos`)
                .then(respuesta => respuesta.json())
                // .then(console.log(respuesta))
                .then(respuesta =>
                    this.setState({
                        loading: false,
                        data: respuesta,
                        hayData: true,
                    }))


        } catch (err) {
            this.setState({
                loading: false,
                error: err
            });
        }
    }

    render() {

        if (this.state.loading === true && !this.state.hayData) {
            return null;
        }

        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="Navbar" id="NavbarHome">
                <Navbar.Brand><NavLink to="/" className="link">
                    <div class="text amarillo">LMB </div><div class="text verde">Aprenda </div><div class="text azul">Português</div></NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    
                    <Nav className="ml-auto">
                        {this.state.data.map(grupo => {
                            return (
                                    <Grupos grupo={grupo} key={grupo.id} />
                            );
                        })}

                        
                       
                     
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default NavbarLMB