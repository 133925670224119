import React, { Component } from 'react';

import './styles/Loader.css';

export default class Loader extends Component {
    
    render() {
        let random = Math.floor(Math.random() * 8);
    
        return (
            <div class={`loader`+random}></div>
        );
    }
}