import React from 'react';
import PageLoading from '../components/PageLoading';
import PageError from '../components/PageError';
import { Layout } from '../components/Layout/index'
import TarjetaList from '../components/TarjetaList'

const config = require('../config');  // url de la API
const fetch = require('node-fetch');

class Categorias extends React.Component {

    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    componentDidMount() {


        this.fetchData();
        this.intervalId = setInterval(this.fetchData, 50000);

    }


    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }

    fetchData = async () => {
        this.setState({ loading: true, error: null })

        try {

            fetch(`${config.api.url}/contenido/${this.props.match.params.grupo_id}/${this.props.match.params.categoria_id}`)
                .then(respuesta => respuesta.json())
                .then(respuesta =>
                    this.setState({
                        loading: false,
                        data: respuesta,
                        hayData: true,
                    }))


        } catch (err) {
            this.setState({
                loading: false,
                error: err
            });
        }
    }



    render() {

        if (this.state.loading === true && !this.state.hayData) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError error={this.state.error} />
        }
        return (
            <Layout
                title={`LMB`}>
                <TarjetaList tarjetas={this.state.data} ocultarcategoria={true}/>
            </Layout>

        );
    }
}

export default Categorias;