import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../styles/Navbar.css'
import Categorias from './Categorias'
//import NavbarCursos from './NavbarCursos';

const config = require('../../config'); // url de la API

class NavbarLMB extends React.Component {
    grupo = this.props.grupo
    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        }
    }

    componentDidMount() {


        this.fetchData();
        this.intervalId = setInterval(this.fetchData, 500000);

    }


    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }


    // Se buscan las categorías del grupo
    fetchData = async() => {
        this.setState({ loading: true, error: null })

        try {
     
            fetch(`${config.api.url}/categorias/${this.grupo.id}`)
                .then(respuesta => respuesta.json())
                .then(respuesta =>
                    this.setState({
                        loading: false,
                        data: respuesta,
                        hayData: true,
                    }))


        } catch (err) {
            this.setState({
                loading: false,
                error: err
            });
        }
    }

    render() {

        if (this.state.loading === true || !this.state.hayData) {
            return null;
        }

        return ( //se arma el menú con los grupos y sus categorias


            <NavDropdown title={this.grupo.nombre} id="collasible-nav-dropdown">
                {this.state.data.map(categoria => {

                    return (
                        
                        <Categorias categoria={categoria} key={categoria.id} grupo_id={this.grupo.id} />

                    );
                })}
                <NavDropdown.Divider />
                <NavDropdown.Item href={`/contenido/${this.grupo.id}`}className="link">Ver Tudo</NavDropdown.Item>

            </NavDropdown>

        )
    }
}

export default NavbarLMB