import React from 'react';
import NavbarLMB from './Nav/Navbar';


function Layout(props) {

    return (
        <React.Fragment>
            <NavbarLMB />
            {props.children}
        </React.Fragment>
        
    );
}

export default Layout;