import React from 'react';
import Collapse from 'react-bootstrap/Collapse'
import ProgressBar from 'react-bootstrap/ProgressBar'
import '../pages/styles/Luciana.css';



function Skill() {


        return (

            <section class="sect skills" id="skills">
                <div class="container">
                    <div class="head-content d-flex align-items-baseline">

                        <h3 class="sect-title">Certificados</h3>
                    </div>
                    <div class="row" id="skills-content">

                        <div class="skills-col col-12 col-md-6">
                            <div class="skill-item">
                                <p>Título del curso</p>
                                <ProgressBar now={95} label='95' />
                            </div>
                        </div>

                        <div class="skills-col col-12 col-md-6">
                            <div class="skill-item">
                                <p>Título del curso</p>
                                <ProgressBar now={85} label='85' />
                            </div>
                        </div>

                       
                    </div>

                </div>
            </section>


        );


}

export default Skill;