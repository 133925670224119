const BASE_URL = 'https://lmb-aprendaportugues.com.ar/api/public/api';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const randomNumber = (min = 0, max = 1) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
const simulateNetworkLatency = (min = 30, max = 1500) =>
    delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
    await simulateNetworkLatency();

    options.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const url = BASE_URL + endpoint;
    const result = await fetch(url, options);
    const data = await result.json();

    return data;
}

const api = {
    grupos: {
        list() {
            return callApi('/grupos');
            //return [];
            //throw new Error('Not found');
        },
    },    
    textos: {
        list() {
            let textos =  callApi('/textos');
            return textos
            //return [];
            //throw new Error('Not found');
        },
    },
    admin: {
        list() {
            return callApi('/badges');
            //return [];
            //throw new Error('Not found');
        },
        login(user) {
            return callApi(`/login`, {
                method: 'POST',
                body: JSON.stringify(user),
            });
        },
        //sube la imagen del avatar
        update(token, avatar) {
            /*return callApi(`/admin/login`, {
                method: 'POST',
                body: JSON.stringify(user),
            });*/
        },
        read(badgeId) {
            return callApi(`/badges/${badgeId}`);
        },
        update(badgeId, updates) {
            return callApi(`/badges/${badgeId}`, {
                method: 'PUT',
                body: JSON.stringify(updates),
            });
        },
        // Lo hubiera llamado `delete`, pero `delete` es un keyword en JavaScript asi que no es buena idea :P
        remove(badgeId) {
            return callApi(`/badges/${badgeId}`, {
                method: 'DELETE',
            });
        },
    },
};

export default api;