import React from 'react';
import './styles/Tarjeta.css';

function TarjetaFooter(props) {

    if (props.categoria_id && props.grupo_id) {

    return (

        <div className="card-footer bg-transparent border-success"><a href={`/contenido/${props.grupo_id}/${props.categoria_id}`} className="link">Ver mais temas</a>
        </div>

    );}
    else return null

}

export default TarjetaFooter;