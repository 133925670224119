import React from 'react';
import Collapse from 'react-bootstrap/Collapse'
import './styles/Luciana.css';
import Skill from '../components/Skill'
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Luciana() {
    return (
        <>
            <selection className="main-info bg-grad" id="main-info">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-6 img-col d-flex
                        align-items-center">
                            <div className="img-container d-flex
                            justify-content-center">
                                <img src='Luciana.jpg' alt="Luciana Mabel
                                Burgueño" />
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-xl-6 txt-col">
                            <div className="text-container">
                                <h1 className="sect-title text-uppercase h2">Luciana Mabel
                                Burgueño</h1>
                                <p>Profesora de Portugués</p>
                                <ul className="list-unstyled basic-info">
                                    <li><FontAwesomeIcon icon={faCalendarAlt} /> 8 Marzo,
                                        1981</li>
                                    <li><FontAwesomeIcon icon={faPhone} /> +54 9 11 6137-4093</li>
                                    <li><FontAwesomeIcon icon={faAt} />
                                          luciana@lmb-aprendaportugues.com</li>
                                    <li><FontAwesomeIcon icon={faMapMarkedAlt} /> Argentina</li>
                                </ul>
                                <ul className="list-inline social-nw">
                                    <li className="list-inline item"><a href='#'><i
                                        className="fab fa-linkedin"></i></a></li>
                                    <li className="list-inline item"><a href='#'><i
                                        className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </selection>


            <Skill/>
    

            <section className="sect porfolio bg-grad-b" id="portfolio">
                <div className="container">
                    <div className="head-content d-flex align-items-baseline">
                        
                        <h3 className="sect-title">Experiencia</h3>
                    </div>
                </div>

            
                <div className="container-fluid" id="portfolio-content">
                    <div className="card-container d-flex flex-wrap justify-content-around align-items-center">

                        <div className="card">
                            <div className="card-body">
                                <FontAwesomeIcon icon={faSchool} className='icon'/>
                                <h5 className="card-title">Nombre del Instituto</h5>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptates dignissimos beatae ipsam vel. Laboriosam sequi quia explicabo ducimus, eveniet ex vitae, natus voluptatum ut molestiae quaerat in amet nemo exercitationem?</p>
                                <a href="#" className="btn btn-primary">Ir al sitio web</a>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <FontAwesomeIcon icon={faSchool} className='icon'/>
                                <h5 className="card-title">Nombre del Instituto</h5>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptates dignissimos beatae ipsam vel. Laboriosam sequi quia explicabo ducimus, eveniet ex vitae, natus voluptatum ut molestiae quaerat in amet nemo exercitationem?</p>
                                <a href="#" className="btn btn-primary">Ir al sitio web</a>
                            </div>
                        </div>
                    </div>
                </div >
           
            </section >
        </>
    )
}

export default Luciana;