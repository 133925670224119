import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaExpandAlt } from 'react-icons/fa';
import { Document, Page } from 'react-pdf';

import './styles/Tarjeta.css';
import TarjetaFooter from './TarjetaFooter';
import Adjuntos from './Adjuntos';

const config = require('../config');  // url de la API
const fetch = require('node-fetch');

class Curso extends React.Component {
    titulo = '';
    state = {
        show: false,
        archivo: '',
        documento: '',
        numPages: null,
        setNumPages: null,
        pageNumber: 1,
        setPageNumber: 1
    }



    //se obtiene el documento adjunto
    /* fetchDocumento = async (archivo) => {
 
         this.setState({ loading: true, error: null })
         if (archivo !== '') {
             let uri = '/adjunto/' + archivo
 
             try {
                 console.log(uri)
                 fetch(`${config.api.url}${uri}`)
                    // .then(respuesta =>console.log(respuesta))
                     .then(respuesta => this.setState({
                         //loading: false,
                         archivo: respuesta.url,
                         //error: false,
                         show: true
 
                     }))
 
 
 
             } catch (err) {
                 console.log(err)
                 this.setState({
                     //loading: false,
                     //error: err,
                     show: true,
                 });
             }
         }
 
     }*/

    onDocumentLoadSuccess({ numPages }) {
        // this.setState({ numPages: numPages })

    }
    render() {
        if (this.props.categoria_id)
            this.titulo = this.props.titulo
        else
            this.titulo = this.props.categoria


        if(this.props.descuento)
            var contenidoTarjeta = (
                <React.Fragment>
                    <div className="col">
                        <div className="container">
                            <div className="card border-success mb-3 mt-3">
                                <div className="box">
                                    <div className="content">
                                        <div className="card-header bg-transparent border-success"><a href={this.props.contenido}>{this.props.categoria}</a>

                                        </div>
                                        <div className="card-body text-success">
                                            <h2 className="card-title">{this.props.titulo}</h2>
                                            <br />

                                            <a href={this.props.contenido} target="_blank">Haz click aquí y accede al curso con un {this.props.descuento}% de descuento!!!</a>

                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </React.Fragment>
            );
        else
        contenidoTarjeta = (
            <React.Fragment>
                <div className="col">
                    <div className="container">
                        <div className="card border-success mb-3 mt-3">
                            <div className="box">
                                <div className="content">
                                    <div className="card-header bg-transparent border-success"><a href={this.props.contenido}>{this.props.categoria}</a>
                                    </div>
                                    <div className="card-body text-success">
                                        <h2 className="card-title">{this.props.titulo}</h2>
                                        <br/>
                                        
                                        <a href={this.props.contenido} target="_blank">Haz click aquí para acceder a miles de cursos</a>
                                      
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </React.Fragment>
        );

        return contenidoTarjeta
    }
}

export default Curso;