import React from 'react';


class Adjuntos extends React.Component {

    render() {
        return (
            <div><a href={"https://lmb-aprendaportugues.com.ar/admin/storage/app/contenido/" + this.props.adjunto.archivo} target="_blank">{this.props.adjunto.nombre}</a></div>

        )
    }
}

export default Adjuntos