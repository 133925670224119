import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

export const Layout = ({ children, title, subtitle}) => {
    return (
        <Fragment>
            <Helmet>
                {title && <title>{title} | Aprenda Português </title>}
                {subtitle && <meta name='description' content={subtitle} />}
            </Helmet>
            <div>
                {children}
            </div>
        </Fragment>
    )

}