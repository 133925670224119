import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaExpandAlt } from 'react-icons/fa';
import { Document, Page } from 'react-pdf';
import ReactPlayer from 'react-player'

import './styles/Tarjeta.css';
import TarjetaFooter from './TarjetaFooter';
import Adjuntos from './Adjuntos';

const config = require('../config');  // url de la API
const fetch = require('node-fetch');

class Tarjeta extends React.Component {
 
    titulo = '';
    state = {
        show: false,
        archivo: '',
        documento: '',
        numPages: null,
        setNumPages: null,
        pageNumber: 1,
        setPageNumber: 1
    }



    //se obtiene el documento adjunto
    fetchDocumento = async (archivo) => {
        console.log('adjuntos');

        this.setState({ loading: true, error: null })
        if (archivo !== '') {
            let uri = '/adjunto/' + archivo

            try {
            
                fetch(`${config.api.url}${uri}`)
                    // .then(respuesta =>console.log(respuesta))
                    .then(respuesta => this.setState({
                        //loading: false,
                        archivo: respuesta.url,
                        //error: false,
                        show: true

                    }))

                /*.then(respuesta =>
                    this.setState({
                        loading: false,
                        data: respuesta,
                        error: false,
                        show: true,
                        archivo: ''
                    })
                )*/


            } catch (err) {
                console.log(err)
                this.setState({
                    //loading: false,
                    //error: err,
                    show: true,
                });
            }
        }

    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages: numPages })
        //setNumPages(numPages);
    }
    render() {
        if (this.props.categoria_id)
            this.titulo = this.props.titulo
        else
            this.titulo = this.props.categoria


        if (this.props.path !== undefined) {
            console.log('videos')
            //  VIDEOS
            var contenidoTarjeta = (
                <React.Fragment>
                    <div className="col">
                        <div className="container">
                            <div className="card border-success mb-3 mt-3">
                                <div className="box">
                                    <div className="content">
                                        <div className="card-header bg-transparent border-success">{this.props.categoria}

                                            <button onClick={() =>
                                                this.setState({
                                                    show: true
                                                }
                                                )}
                                                className="btn ml-auto">
                                                <FaExpandAlt />
                                            </button>


                                        </div>
                                        <div className="card-body text-success">
                                            <h2 className="card-title">{this.props.titulo}</h2>
                                            <ReactPlayer
                                                url={this.props.contenido}
                                                className='react-player'
                                                width='100%'
                                            />
                                        </div>
                                        <TarjetaFooter categoria_id={this.props.categoria_id} grupo_id={this.props.grupo_id} />

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Modal
                        show={this.state.show}
                        onHide={() => this.setState({
                            show: false
                        })}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.titulo}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ReactPlayer
                                url={this.props.contenido}
                                className='react-player'
                                width='100%'
                           
                            />
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            );

        } else if (Array.isArray(this.props.contenido)) {
             console.log('adjuntos')
            //ADJUNTOS
            contenidoTarjeta = (
                <React.Fragment>
                    <div className="col">
                        <div className="container">
                            <div className="card border-success mb-3 mt-3">
                                <div className="box">
                                    <div className="content">
                                        <div className="card-header bg-transparent border-success">{this.props.categoria}



                                        </div>
                                        <div className="card-body text-success">
                                            <h2 className="card-title">{this.props.titulo}</h2>
                                            <ul>
                                                {this.props.contenido.map((contenido, index) => {

                                                    return (<li>
                                                        <Adjuntos adjunto={contenido} key={contenido.id} />

                                                    </li>


                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <TarjetaFooter categoria_id={this.props.categoria_id} grupo_id={this.props.grupo_id} />

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Modal
                        show={this.state.show}
                        onHide={() => this.setState({
                            show: false,
                            archivo: ''
                        })}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.archivo}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            )

        } else {
            // TEXTO
            contenidoTarjeta = (
                <React.Fragment>
                    <div className="col">
                        <div className="container">
                            <div className="card border-success mb-3 mt-3">
                                <div className="box">
                                    <div className="content">
                                        <div className="card-header bg-transparent border-success">{this.props.categoria}

                                            <button onClick={() =>
                                                this.setState({
                                                    show: true
                                                }
                                                )}
                                                className="btn ml-auto">
                                                <FaExpandAlt />
                                            </button>


                                        </div>
                                        <div className="card-body text-success">
                                            <h2 className="card-title">{this.props.titulo}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.contenido }} />
                                        </div>
                                        <TarjetaFooter categoria_id={this.props.categoria_id} grupo_id={this.props.grupo_id} />

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Modal className="Modal"
                        show={this.state.show}
                        onHide={() => this.setState({
                            show: false
                        })}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.titulo}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p dangerouslySetInnerHTML={{ __html: this.props.contenido }} />
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            );
        }
        return contenidoTarjeta
    }
}

export default Tarjeta;