import React from 'react';

import './styles/Home.css';
import { Layout } from '../components/Layout/index'


class Home extends React.Component {


    render() {

        //muestro dicamicamnete el fondo
        let random = Math.floor(Math.random() * 2);

        if(random === 0)
        return (
                <Layout
                title={`LMB Aprenda Português`}>
                <section className="bienvenidos">
                    <div className="container">
                        <div className="texto-bienvenidos text-center">
                           
                            <h2>Bem-vindo</h2>
                           
                        </div>
                    </div>
                </section>
                </Layout>

        );
        else return (
            <Layout
                title={`LMB Aprenda Português`}>
                <section className="bienvenidos2">
                    <div className="container">
                        <div className="texto-bienvenidos text-center">

                            <h2>Bem-vindo</h2>

                        </div>
                    </div>
                </section>
            </Layout>

        );
    }
}

export default Home;