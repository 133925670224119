import React, { useState } from "react";
import "./styles/Login.css";
import api from '../../api';
import PageLoading from '../../components/PageLoading';
import FormLogin from '../../components/admin/FormLogin';
import $ from 'jquery'
import { Redirect } from 'react-router-dom'

class Login extends React.Component {
    state = {
        loading: false,
        error: null,
        token: null,
        form: {
            username: '',
            password: ''
        },
    };

    handleChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true, error: null });
        try { // llamo a la API para realizar el login
            await api.admin.login(this.state.form)
                .then(respuesta =>
                    this.setState({
                        data: respuesta,
                    }));
            this.setState({ loading: false });

            if (this.state.data.status === 'success'){ // si el login es correcto
                this.setState({ token: this.state.data.token.token }); // obtengo el token

                localStorage.setItem('token', this.state.data.token.token);
            }

            else { //muestro el error
                $('form').addClass('wrong-entry');
                setTimeout(function () {
                    $('form').removeClass('wrong-entry');
                }, 5000);


            }


        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    render() { // si odavía está procesando la API
        if (this.state.loading) {
            return <PageLoading />;
        }
        if (this.state.token !== null)
        //si se logueó exitosamente
            return <Redirect to='/admin/dashboard' />
        return (
            <FormLogin
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                formValues={this.state.form}
                error={this.state.error}
            />
        );
    }
}

export default Login;