import React from 'react';
import PageLoading from '../components/PageLoading';
import PageError from '../components/PageError';
import { Layout } from '../components/Layout/index'
import CursoList from '../components/CursoList'

const config = require('../config');  // url de la API
const fetch = require('node-fetch');

class Cursos extends React.Component {
    params = this.props.match.params;
    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        },
        loadingV: true,
        errorV: null,
        hayVideos: false,
        videos: {
            results: []
        },
        loadingA: true,
        errorA: null,
        hayAdjuntos: false,
        adjuntos: {
            results: []
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            videos: [],
            adjuntos: []
        };

    }

    componentDidMount() {
        //se buscan los datos según los parámetros de la URL
        /*let categoria_id = null;
        if (this.params.categoria_id)
            categoria_id = this.params.categoria_id

        this.fetchData(categoria_id)*/
    }


    fetchData = async (categoria_id) => {
        this.fetchContenido(categoria_id);
        this.fetchVideos(categoria_id);
        this.fetchAdjuntos(categoria_id);
    }

    // se busca el contenido a mostrar
    fetchContenido = async (categoria_id) => {
        console.log('contenido');
        this.setState({ loading: true, error: null })

        let uri = ''

        //armo la url completa para buscar los datos
        if (categoria_id)
            uri = '/textos/' + this.params.grupo_id + '/' + categoria_id
        else
            uri = '/textos/' + this.params.grupo_id

        try {

            fetch(`${config.api.url}${uri}`)
                .then(respuesta => respuesta.json())
                .then(respuesta =>
                    this.setState({
                        loading: false,
                        data: respuesta,
                        hayData: true,
                    }))


        } catch (err) {
            this.setState({
                loading: false,
                error: err
            });
        }
    }

    fetchVideos = async (categoria_id) => {
        this.setState({ loadingV: true, errorV: null })
        let uri = ''

        //armo la url completa para buscar los datos
        if (categoria_id)
            uri = '/videos/' + this.params.grupo_id + '/' + categoria_id
        else
            uri = '/videos/' + this.params.grupo_id

        try {

            fetch(`${config.api.url}${uri}`)
                .then(respuesta => respuesta.json())
                .then(respuesta =>
                    this.setState({
                        loadingV: false,
                        videos: respuesta,
                        hayVideos: true,
                    }))


        } catch (err) {
            this.setState({
                loadingV: false,
                errorV: err
            });
        }
    }

    fetchAdjuntos = async (categoria_id) => {
        this.setState({ loadingA: true, errorA: null })
        let uri = ''

        //armo la url completa para buscar los datos
        if (categoria_id)
            uri = '/adjuntos/' + this.params.grupo_id + '/' + categoria_id
        else
            uri = '/adjuntos/' + this.params.grupo_id

        try {

            fetch(`${config.api.url}${uri}`)
                .then(respuesta => respuesta.json())
                .then(respuesta =>
                    this.setState({
                        loadingA: false,
                        adjuntos: respuesta,
                        hayAdjuntos: true,
                    }))


        } catch (err) {
            this.setState({
                loadingA: false,
                errorA: err
            });
        }
    }

    render() {

        //muestro la amimación de carga mientras se buscan los datos
        /*if (this.state.loading === undefined ||
            this.state.loadingV === undefined ||
            this.state.loadingA === undefined ||
            (this.state.loading === true && !this.state.hayData) ||
            (this.state.loadingV === true && !this.state.hayVideos) ||
            (this.state.loadingA === true && !this.state.hayAdjuntos)) {

            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError error={this.state.error} />
        }

        let tarjetas = []
        let tarjeta = null
        let cont = 0;
        for (let row = 0; row < this.state.data.length; row++){
            tarjeta = this.state.data[row];
            tarjeta.key = cont
            cont++
            tarjetas.push(tarjeta)
        }
        
        //agrego los videos a las tarjetas
        for (let row = 0; row < this.state.videos.length; row++)
        {
            tarjeta = this.state.videos[row];
            tarjeta.key = cont
            cont++
            tarjetas.push(tarjeta)
        }

        for (let row = 0; row < this.state.adjuntos.length; row++)
        {
            tarjeta = this.state.adjuntos[row];
            tarjeta.key = cont
            cont++
            tarjetas.push(tarjeta)
        }


        let tarjetasOrdenadas = tarjetas.sort((a, b) => (a.fecha < b.fecha ? 1 : a.fecha > b.fecha ? -1 : 0))

        //título de la página
        let title = 'LMB'
        if (tarjetasOrdenadas.length > 0){
  
            title = tarjetasOrdenadas[0].grupo.nombre
        }*/
        return ( // envio categoria_id para saber si se está viendo alguna categoría
            <Layout
                title='Descuentos en Cursos'>
                <CursoList //tarjetas={tarjetasOrdenadas} categoria_id={this.params.categoria_id} 
                />
            </Layout>

        );
    }
}

export default Cursos;