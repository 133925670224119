import React, { useState } from "react";
import "./styles/Login.css";
import $ from 'jquery'

export default function FormLogin(props) {
    

    $(document).ready(function () {
        $('#password').on("focusin", function () {
            console.log('clik')
            $('form').addClass('up')
        });
        $('#password').on("focusout", function () {
            $('form').removeClass('up')
        });

        // Panda Eye move
        $(document).on("mousemove", function (event) {
            var dw = $(document).width() / 15;
            var dh = $(document).height() / 15;
            var x = event.pageX / dw;
            var y = event.pageY / dh;
            $('.eye-ball').css({
                width: x,
                height: y
            });
        });

        // validation


        $('.btn').click(function () {
            
            $('form').addClass('wrong-entry');
            setTimeout(function () {
                $('form').removeClass('wrong-entry');
            }, 3000);
        });
    })

    return (<div>
        <div class="panda">
            <div class="ear"></div>
            <div class="face">
                <div class="eye-shade"></div>
                <div class="eye-white">
                    <div class="eye-ball"></div>
                </div>
                <div class="eye-shade rgt"></div>
                <div class="eye-white rgt">
                    <div class="eye-ball"></div>
                </div>
                <div class="nose"></div>
                <div class="mouth"></div>
            </div>
            <div class="body"> </div>
            <div class="foot">
                <div class="finger"></div>
            </div>
            <div class="foot rgt">
                <div class="finger"></div>
            </div>
        </div>
        <form onSubmit={props.onSubmit}>
            <div class="hand"></div>
            <div class="hand rgt"></div>
            <h1>Conecte-se</h1>
            <div class="form-group">
                <input required="required" class="form-control" name="username" onChange={props.onChange} value={props.formValues.username}/>
                <label class="form-label">Nome    </label>
            </div>
            <div class="form-group">
                <input id="password" type="password" required="required" class="form-control" name="password" onChange={props.onChange} value={props.formValues.password}/>
                <label class="form-label">Senha</label>
                <p class="alert">Credenciais inválidas...!!</p>
                <button class="btn">Conecte-se </button>
            </div>
        </form>
    </div>
    )
}