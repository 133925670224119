import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

//Páginas:
import Home from '../pages/Home';
import Luciana from '../pages/luciana'
import Categorias from '../pages/Categorias';
import Contenido from '../pages/Contenido'; //muestra el contenido dinamicamente

import NotFound from '../pages/NotFound';
import Login from '../pages/admin/Login';   //login admin
//import HomeAdmin from '../pages/admin/Home';
import HomeAdmin from '../pages/admin/Admin';
import Dashboard from '../pages/admin/Dashboard';

import Cursos from '../pages/Cursos'; //cursos externos
import Layout from '../components/Layout';
import DashboardPage from "../pages/admin/Dashboard";
import Admin from "../pages/admin/Admin";
import UserProfile from "../pages/admin/UserProfile/UserProfile";
import TableList from "../pages/admin/TableList/TableList";
import Typography from "../pages/admin/Typography/Typography";
import Icons from "../pages/admin/Icons/Icons";
import Maps from "../pages/admin/Maps/Maps";
import NotificationsPage from "../pages/admin/Notifications/Notifications";
function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/luciana" component={Luciana} />
                    <Route exact path="/categorias/:grupo_id/:categoria_id" component={Categorias} />
                    <Route exact path="/contenido/:grupo_id" component={Contenido} />
                    <Route exact path="/contenido/:grupo_id/:categoria_id" component={Contenido} />
                    <Route exact path="/descuentos" component={Cursos} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/admin" component={Admin} />
                    <Route exact path="/admin/home" component={HomeAdmin} />
                    
                   
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}



export default App;