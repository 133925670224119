import React from 'react';
import TarjetaListTitle from './TarjetaListTitle';
import Tarjeta from './Tarjeta'
const config = require('../config');  // url de la API

class TarjetaListItem extends React.Component {

    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            data: []
        };

    }

    componentDidMount() {

        this.fetchVideo()

    }



    //se obtiene el video
    fetchVideo = async () => {

        this.setState({ loading: true, error: null })

        if (this.props.tarjeta.path !== undefined) { //si es un video
            let uri = '/video/' + this.props.tarjeta.contenido

            try {

                fetch(`${config.api.url}${uri}`)
                    //.then(respuesta => respuesta.json())
                    .then(respuesta =>
                        this.setState({
                            loading: false,
                            data: respuesta,
                            hayData: true,
                        }))


            } catch (err) {
                this.setState({
                    loading: false,
                    error: err
                });
            }
        }
        // si no es un video
        else
            this.setState({
                loading: false,
                hayData: true,
            })
    }

    render() {

        if (this.state.loading === undefined ||
            (this.state.loading === true && !this.state.hayData)) {
            return null;
        }

        if (this.props.categoria_id) {
            console.log(this.props.categoria_id)
            return (


                <Tarjeta //datos a mostrar en la tarjeta
                    categoria={this.props.tarjeta.titulo}
                    contenido={this.props.tarjeta.contenido}
                    path={this.state.data.url}
                />


            );
        }
        else{
  
            
        
            return (

                
                    <Tarjeta //datos a mostrar en la tarjeta
                        categoria={this.props.tarjeta.categoria.titulo}
                        titulo={this.props.tarjeta.titulo}
                        contenido={this.props.tarjeta.contenido}
                        categoria_id={this.props.tarjeta.categoria.id}
                        grupo_id={this.props.tarjeta.grupo.id}
                        ocultarcategoria={this.props.ocultarcategoria}
                        path={this.state.data.url}
                    />

            
            );
        }
    }
}



function useSearchTarjetas(tarjetas) {
    const [query, setQuery] = React.useState('');
    const [filteredTarjetas, setFilteredTarjetas] = React.useState(tarjetas);

    React.useMemo(() => {
        const result = tarjetas.filter(tarjeta => {
            return `${tarjeta.categoria.titulo} ${tarjeta.titulo}`.toLowerCase().includes(query.toLowerCase());
        });
        setFilteredTarjetas(result)
    }, [tarjetas, query]);

    return { query, setQuery, filteredTarjetas }
}


function TarjetaList(props) {

    const tarjetas = props.tarjetas;
    const categoria_id = props.categoria_id;
    const { query, setQuery, filteredTarjetas } = useSearchTarjetas(tarjetas);

    if (filteredTarjetas.length === 0) {

        return (
            
            <div className="container">

                <div className="form-group">
                    <div className="row">
                        <TarjetaListTitle categoria_id={categoria_id}
                            tarjeta={tarjetas[0]} />
                        <div className="col-sm-6 mt-3">
                            <input type="text" className="form-control" placeholder='Filtrar conteúdo' value={query} onChange={(e) => {
                                setQuery(e.target.value)
                            }}
                            />
                        </div>
                    </div>
                </div>
                <h3>No hay datos para el filtro ingresado</h3>

            </div>
        );
    }


    return (

        <div className="container">
            <div className="form-group">
                <div className="row">
                    <TarjetaListTitle categoria_id={categoria_id}
                        tarjeta={tarjetas[0]} />

                    <div className="col-sm-6 mt-3">
                        <input type="text" className="form-control" placeholder='Filtrar conteúdo' value={query} onChange={(e) => {
                            setQuery(e.target.value)
                        }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {filteredTarjetas.map((tarjeta, index) => { //recorre las tarjetas
                    return (
                        <TarjetaListItem tarjeta={tarjeta} key={tarjeta.id} categoria_id={categoria_id} />

                    );
                })}

            </div>
        </div>

    );

}

export default TarjetaList;