import React from 'react';
import TarjetaListTitle from './TarjetaListTitle';
import Curso from './Curso'
const config = require('../config');  // url de la API

class TarjetaListItem extends React.Component {

    state = {
        loading: true,
        error: null,
        hayData: false,
        data: {
            results: []
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            data: []
        };

    }

    componentDidMount() {

        this.fetchVideo()

    }



    //se obtiene el video
    fetchVideo = async () => {

        this.setState({ loading: true, error: null })

        if (this.props.tarjeta.path !== undefined) { //si es un video
            let uri = '/video/' + this.props.tarjeta.contenido

            try {

                fetch(`${config.api.url}${uri}`)
                    //.then(respuesta => respuesta.json())
                    .then(respuesta =>
                        this.setState({
                            loading: false,
                            data: respuesta,
                            hayData: true,
                        }))


            } catch (err) {
                this.setState({
                    loading: false,
                    error: err
                });
            }
        }
        // si no es un video
        else
            this.setState({
                loading: false,
                hayData: true,
            })
    }

    render() {

        if (this.state.loading === undefined ||
            (this.state.loading === true && !this.state.hayData)) {
            return null;
        }

        if (this.props.categoria_id) {

            return (


                <Curso //datos a mostrar en la tarjeta
                    categoria={this.props.tarjeta.titulo}
                    contenido={this.props.tarjeta.contenido}
                    path={this.state.data.url}
                />


            );
        }
        else
            return (


                <Curso //datos a mostrar en la tarjeta
                    categoria={this.props.tarjeta.categoria.titulo}
                    titulo={this.props.tarjeta.titulo}
                    contenido={this.props.tarjeta.contenido}
                    categoria_id={this.props.tarjeta.categoria.id}
                    grupo_id={this.props.tarjeta.grupo.id}
                    ocultarcategoria={this.props.ocultarcategoria}
                    path={this.state.data.url}
                />


            );
    }
}


/*
function useSearchTarjetas(tarjetas) {
    const [query, setQuery] = React.useState('');
    const [filteredTarjetas, setFilteredTarjetas] = React.useState(tarjetas);

    React.useMemo(() => {
        const result = tarjetas.filter(tarjeta => {
            return `${tarjeta.categoria.titulo} ${tarjeta.titulo}`.toLowerCase().includes(query.toLowerCase());
        });
        setFilteredTarjetas(result)
    }, [tarjetas, query]);

    return { query, setQuery, filteredTarjetas }
}
*/

function CursoList(props) {

    const tarjetas = props.tarjetas;
    const categoria_id = props.categoria_id;
    //  const { query, setQuery, filteredTarjetas } = useSearchTarjetas(tarjetas);
    /*
        if (filteredTarjetas.length === 0) {
    
            return (
                
                <div className="container">
    
                    <div className="form-group">
                        <div className="row">
                            <TarjetaListTitle categoria_id={categoria_id}
                                tarjeta={tarjetas[0]} />
                            <div className="col-sm-6 mt-3">
                                <input type="text" className="form-control" placeholder='Filtrar conteúdo' value={query} onChange={(e) => {
                                    setQuery(e.target.value)
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <h3>No hay datos para el filtro ingresado</h3>
    
                </div>
            );
        }
    
    
        return (
    
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <TarjetaListTitle categoria_id={categoria_id}
                            tarjeta={tarjetas[0]} />
    
                        <div className="col-sm-6 mt-3">
                            <input type="text" className="form-control" placeholder='Filtrar conteúdo' value={query} onChange={(e) => {
                                setQuery(e.target.value)
                            }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {filteredTarjetas.map((tarjeta, index) => { //recorre las tarjetas
                        return (
                            <TarjetaListItem tarjeta={tarjeta} key={tarjeta.id} categoria_id={categoria_id} />
    
                        );
                    })}
    
                </div>
            </div>
    
        );*/

    return (
        <React.Fragment>
            <h1 id='titulo_categoria'>Descuentos en cursos online</h1>

            <div className="container">

                <div className="form-group">
                    <div className="row">
                        <Curso //datos a mostrar en la tarjeta
                            categoria='Aprende a utilizar una Plataforma Social Educativa'
                            titulo='Aplicar un ambiente virtual de aprendizaje. Crear clases para los diferentes grupos de alumnos en un ambiente virtual de aprendizaje. Publicar materiales educativos y REA para los estudiantes. Evaluar a los estudiantes de manera grupal.'
                            contenido='https://www.tutellus.com/tecnologia/software/aprende-a-utilizar-una-plataforma-social-educativa-30515?cupon=DOCENTE10&affref=789b32f48b834707a75f47ae65559014'
                            descuento='10'
                        />
                        <Curso //datos a mostrar en la tarjeta
                            categoria='Curso de Microsoft Teams: "desde cero a experto"'
                            titulo='Domina la herramienta Microsoft Teams para poder realizar trabajos colaborativos.'
                            contenido='https://www.tutellus.com/tecnologia/software/curso-de-microsoft-teams-desde-cero-a-experto-32637?cupon=SUPER_DESC&affref=789b32f48b834707a75f47ae65559014'
                            descuento='70'
                        />

                        <Curso //datos a mostrar en la tarjeta
                            categoria='Udemy'
                            titulo='En Udemy puedes encontrar cursos de Desarrollo, Negocios, Finanzas y Contabilidad, Informática y Software, Productividad en la Oficina, Desarrollo Personal, Diseño, Maketing, Salud y fitness, Música y mucho más!!!'
                            contenido='https://app.partnersmy.com/scripts/99320dvw2c2?a_aid=5fdfcaaf7da8d&a_bid=e2f3680e'

                        />

                        <Curso //datos a mostrar en la tarjeta
                            categoria='Coursera'
                            titulo='En Coursera puedes aprender sobre Artes y Humanidades, Negocios, Ciencias de la Computación Ciencia de Datos, Tecnología de la Información, Salud, Matemáticas y Lógica, Desarrollo Personal, Ciencias Físicas e Ingeniería, Ciencias Sociales, Idioma y mucho más!!!'
                            contenido='https://app.partnersmy.com/scripts/99320dvw2c2?a_aid=5fdfcaaf7da8d&a_bid=5442a3db'

                        />
                    </div></div></div>
        </React.Fragment>
    )

}

export default CursoList;