import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../styles/Navbar.css'

class Categorias extends React.Component {
    categoria = this.props.categoria
    grupo_id = this.props.grupo_id //id del grupo

    render() {

        return (
            <NavDropdown.Item
                href={`/contenido/${this.grupo_id}/${this.categoria.id}`} className="link">{this.categoria.titulo} 
            </NavDropdown.Item>

        )
    }
}

export default Categorias