import React from 'react';
import './styles/Tarjeta.css';


// Título de la lista de tarjetas (Nombre de la categoría)
function TarjetaListTitle(props) {

    if (props.categoria_id && props.tarjeta) {
        return (
            
                <div className="col-sm-6 mt-3">
                <h1 id="titulo_categoria">{props.tarjeta.categoria.titulo}</h1>
                </div>
          
        );

    }
    else return null
}

export default TarjetaListTitle;